.form-group {
  &__label {
    @apply block text-sm leading-5 text-gray-700 mb-1;
  }

  &.has-danger {
    .form-control, .react-select__control {
      @apply border-red-500;
    }

    .form-group__label {
      @apply text-red-500;
    }
  }
}

.react-datepicker-wrapper {
  @apply block;
}

.reorder {
  @apply bg-therapygrey text-center cursor-pointer outline-none;
  border-radius: 5px;
  // box-shadow: 0px 0px 8px -6px rgba(0, 0, 0, .5);
  min-height: 40px;
}