li{
    margin-left: 20px;
}
ol{
    list-style: unset;
    list-style-type: decimal;
    margin-left: 20px;
}
td{
    border-width: 2px;
}
@media only screen and (max-width: 400px) {
    li{
        margin-left: 5px;
    }
}