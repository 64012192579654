@tailwind base;

a {
  @apply text-blue-600;
}

th {
  @apply text-left;
}

hr {
  @apply my-4;
}

@tailwind components;
@tailwind utilities;
@import "bootstrap.scss";
@import "components.scss";
@import "nav.scss";

body,
html {
  @apply bg-gray-50 text-gray-700;
}

.nav-tabs {
  @apply border-b-0;

  .nav-link {
    @apply uppercase border-0 border-b-2 border-transparent text-sm font-normal text-gray-600;
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    @apply text-secondary border-secondary;
  }
}

@-webkit-keyframes shaker {
  0% {
    -webkit-transform: translate(2px, 1px) rotate(0deg);
  }

  10% {
    -webkit-transform: translate(-1px, -2px) rotate(-2deg);
  }

  20% {
    -webkit-transform: translate(-3px, 0px) rotate(3deg);
  }

  30% {
    -webkit-transform: translate(0px, 2px) rotate(0deg);
  }

  40% {
    -webkit-transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    -webkit-transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    -webkit-transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    -webkit-transform: translate(2px, 1px) rotate(-2deg);
  }

  80% {
    -webkit-transform: translate(-1px, -1px) rotate(4deg);
  }

  90% {
    -webkit-transform: translate(2px, 2px) rotate(0deg);
  }

  100% {
    -webkit-transform: translate(1px, -2px) rotate(-1deg);
  }
}

.shake {
  display: block;
  animation-name: shaker;
  -webkit-animation-name: shaker;
  -webkit-animation-duration: 4s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;

}

.iframe-modal .modal-content {
  background: transparent;
  border: none;
}

.shake2 {


  & {
    -webkit-animation-name: wobble;
    animation-name: wobble;
    -webkit-animation-duration: 1.25s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -webkit-transform-origin: 50% 100%;
  }
}

@-webkit-keyframes wobble {
  0% {
    -webkit-transform: none;
    transform: none;
  }

  15% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }

  30% {
    -webkit-transform: translate3d(5%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(5%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }

  45% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }

  60% {
    -webkit-transform: translate3d(5%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(5%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }

  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }

  100% {
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes wobble {
  0% {
    -webkit-transform: none;
    transform: none;
  }

  15% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }

  30% {
    -webkit-transform: translate3d(5%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(5%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }

  45% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }

  60% {
    -webkit-transform: translate3d(5%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(5%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }

  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }


  100% {
    -webkit-transform: none;
    transform: none;
  }
}

.signup-type-box {
  // background: rgb(21,86,225);
  // background: linear-gradient(180deg, rgba(21,86,225,1) 0%, rgba(15,55,144,1) 100%);
  // color: #fff;
  // border-radius: 50px;
  // padding: 30px 40px;
  // position: relative;
  min-height: 100%;
  // padding-bottom: 100px;
}

.pricing-wrapper {
  text-align: center;
}

.rel-btn {
  position: absolute;
  bottom: 0;
  // left: 0;
}

.apply-pad {
  padding-bottom: 10px
}

.img_logo {
  height: 60px !important;
  width: auto !important;
  padding: 2px !important;
  // margin-top: -10px !important;
  margin-left: -10px !important;
  padding-left: 0px;
}

.min-120 {
  min-width: 120px;
}

.navbar-toggler-icon {
  margin: auto !important;
}

.navbar-toggler {
  border-color: #ffffffff !important;
  border-width: 1.75px !important;
}

.aim-text-area {
  max-width: 95% !important;
}

.nav-item {
  margin-left: 0px;
  text-align: center !important;
}

.nav-link {
  padding: 10px !important;
}

.dropdown-menu {
  background-color: #ffffffcc !important;
}

.dropdown-item {
  text-align: center !important;
}

.fixed-footer {
  margin-right: 0px !important;
  margin-left: 0px !important;
  line-height: 1.5em;
}


@media (max-width: 768px) {
  .aim-report {
    width: 300px !important;
  }
}

.navbar-collapse {
  padding-top: 10px !important;
}

.notification-badge {
  text-decoration: none;
  padding: 5px 10px;
  border-radius: 50%;
  background-color: red;
  color: white;
}

.notebook {
  background-image: -webkit-linear-gradient(90deg, #d0a090 1px, #d0a090 1px), linear-gradient(#fdf8c8, #fdf8c8 23px, #ccd 23px, #ccd 24px, #fdf8c8 24px);
  background-image: -moz-linear-linear-gradient(90deg, #d0a090 1px, #d0a090 1px), linear-gradient(#fdf8c8, #fdf8c8 23px, #ccd 23px, #ccd 24px, #fdf8c8 24px);
  background-image: -ms-linear-gradient(90deg, #d0a090 1px, #d0a090 1px), linear-gradient(#fdf8c8, #fdf8c8 23px, #ccd 23px, #ccd 24px, #fdf8c8 24px);
  background-image: -o-linear-gradient(90deg, #d0a090 1px, #d0a090 1px), linear-gradient(#fdf8c8, #fdf8c8 23px, #ccd 23px, #ccd 24px, #fdf8c8 24px);
  background-image: linear-gradient(90deg, #d0a090 1px, #d0a090 1px), linear-gradient(#fdf8c8, #fdf8c8 23px, #ccd 23px, #ccd 24px, #fdf8c8 24px);
  background-size: 2px 100%, 100% 24px;
  background-position: 40px top, left 0px;
  background-repeat: no-repeat, repeat;
  line-height: 24px !important;
  background-attachment: local;
  padding-left: 40px;
}

.trash-btn {
  margin-left: 5px;
}

@media (max-width: 450px) {
  .notebook {
    background-position: 8px top, left 0px;
    padding-left: 8px;
  }
}

@media (max-width: 350px) {
  .trash-btn {
    margin-left: 0px;
    margin-top: 5px;
  }
}

.notebook-highlight-1 {
  background-color: rgba(178, 238, 162, 0.8) !important;
  text-align: left;
}

.notebook-highlight-2 {
  background-color: rgba(209, 236, 241, .8) !important;
  text-align: left;

}

.notebook-notes {
  // border-top: solid;
  // border-color: #d0a090;
}

.squiggle-holder {
  position: relative;
  width: 100%;
  height: 48px;
  top: 24px;
}

.squiggle-tinyLine {
  position: absolute;
  /* Cuts off the bottom half of the pattern */
  height: 40px;
  /* For better cross browser consistency, make it larger with width.  */
  width: 1000%;
  /* And then scale it back down with scale, recentering with translateX. */
  transform: translateX(-45%) scale(0.1);
}

.squiggle-tinyLine1 {
  background: linear-gradient(45deg, transparent, transparent 49%, green 49%, transparent 51%);
}

.squiggle-tinyLine2 {
  background: linear-gradient(-45deg, transparent, transparent 49%, green 49%, transparent 51%);
}

.squiggle-tinyLine {
  /* Must be after background definition. */
  background-size: 80px 80px;
}

.notebook h5 {
  line-height: 24px;
  margin-bottom: 1.5rem;
}

.notebook h4 {
  line-height: 24px;
}

.notebook-padding {
  padding: 0.75rem !important;
}

.notebook-table {
  background-color: var(--transparent);
}

.notebook-table thead tr th,
.notebook-table tbody tr td,
.notebook-table tbody tr {

  background-color: var(--transparent) !important;
  border: 0px !important;
  border-left: 3px dashed var(--green) !important;
  border-bottom: 3px dashed var(--green) !important;
}

.notebook_table-text {
  line-height: 1.5rem;
  margin-top: 0px !important;
  margin-bottom: 0px !important;

}

.hover-blue:hover,
.hover-blue:focus {
  background-color: #e7f1ff;
}

.guides_header {
  background: #A384B0;
  color: black;
  border-radius: 5px;
  padding: 10px;

  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
  overflow-anchor: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
}

.guides_header:hover {
  background: #820263;
  color: white;
  border-radius: 5px;
  padding: 10px;
}

.guides_header:after {
  width: 1.5rem;
  height: 1.5rem;
  padding-right: 1.5rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 01.708 0L8 10.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z'/></svg>");
  background-repeat: no-repeat;
  background-size: 1.5rem;
  transition: -webkit-transform .2s ease-in-out;
  transition: transform .2s ease-in-out;
  transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
}

.guides_header.active:after {
  background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529' transform='rotate(180)'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 01.708 0L8 10.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z'/></svg>");
}


.notebook-metrics-container {
  display: grid;
  gap: 5px;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, 1fr);

}

@media (max-width: 900px) {
  .notebook-metrics-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .notebook-metrics-container>.first {
    grid-column: 1 / span 2;
    grid-row: 1 / span 2;

  }

}

.notebook-metrics-container>.first {
  grid-row: 1 / span 2;

}

.notebook-metrics-container>.number {
  height: 50px;
  width: 50px;

  text-align: center;
}

.notebook-metrics-container>div {
  margin: auto;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 10px;
  padding-right: 10px;

}

.reorder {
  display: grid;
  gap: 5px;
  grid-template-columns: 30px 1fr 30px;
}

.invisible {
  visibility: hidden;
}