.desktop-nav-item {
  @apply inline-flex items-center mx-1 px-1 pt-1 border-b-2 border-transparent transition duration-150 ease-in-out;

  &__link {
    @apply text-sm font-medium leading-5 text-gray-500;

    &:hover {
      @apply text-gray-700 border-gray-300 cursor-pointer;
    }

    &--active {
      @apply border-indigo-500 text-gray-900;

      &:focus {
        @apply border-indigo-700;
      }
    }

    &:focus {
      @apply outline-none border-indigo-700 text-gray-700 border-gray-300;
    }
  }
}

.mobile-nav-item {
  @apply block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 transition duration-150 ease-in-out;

  &__link {
    @apply text-sm font-medium leading-5 text-gray-500;

    &:hover {
      @apply text-gray-700 border-gray-300 cursor-pointer;
    }

    &--active {
      @apply border-indigo-500 text-gray-900;

      &:focus {
        @apply border-indigo-700;
      }
    }

    &:focus {
      @apply outline-none border-indigo-700 text-gray-700 border-gray-300;
    }
  }
}