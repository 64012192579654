@font-face {
    font-family: 'DM Sans';
    src: url('DMSans-Bold.woff2') format('woff2'),
        url('DMSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DM Sans';
    src: url('DMSans-BoldItalic.woff2') format('woff2'),
        url('DMSans-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'DM Sans';
    src: url('DMSans-Italic.woff2') format('woff2'),
        url('DMSans-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'DM Sans';
    src: url('DMSans-Medium.woff2') format('woff2'),
        url('DMSans-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DM Sans';
    src: url('DMSans-Regular.woff2') format('woff2'),
        url('DMSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DM Sans';
    src: url('DMSans-MediumItalic.woff2') format('woff2'),
        url('DMSans-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

