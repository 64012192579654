$primary: #ef4885;
$secondary: #19b1c0;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
);

$grid-breakpoints: (
        xs: 0,
        sm: 640px,
        md: 768px,
        lg: 1024px,
        xl: 1280px
);

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/input-group";

.container {
  padding-left: 0;
  padding-right: 0;
}

.btn-link {
  &:focus {
    @apply shadow-none;
  }
}