$number-of-options: 10;
$number-of-options-quiz: 4;
// html, body {
// 	width: 100%;
// 	height: 100%;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	font-family: 'Nunito', sans-serif;
// 	color: #000;
// 	user-select: none;
// }
#form-wrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.rating-form {
	width: 90%;
	max-width: 500px;
	#form-title {
		margin-top: 0;
		font-weight: 400;
		text-align: center;
	}
	.rating-slider {
		display: flex;
		flex-direction: row;
		align-content: stretch;
		position: relative;
        margin-bottom: 100px;

		width: 100%;
		height: 50px;
		user-select: none;
		&::before {
			content: " ";
			position: absolute;
			height: 2px;
			width: 100%;
			width: calc(100% * (#{$number-of-options - 1} / #{$number-of-options}));
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			background: #000;
		}
		input, label {
			box-sizing: border-box;
			flex: 1;
			user-select: none;
			cursor: pointer;
		}
		label {
			display: inline-block;
			position: relative;
			width: 20%;
			height: 100%;
			user-select: none;
			&::before {
				content: attr(data-rating);
				position: absolute;
				left: 50%;
				padding-top: 10px;
				transform: translate(-50%, 45px);
				font-size: 16px;
				letter-spacing: 0.4px;
				font-weight: 400;
				white-space: nowrap;
				opacity: 0.85;
				transition: all 0.15s ease-in-out;
			}
			&::after {
				content: " ";
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				width: 30px;
				height: 30px;
				border: 2px solid #000;
				background: #fff;
				border-radius: 50%;
				pointer-events: none;
				user-select: none;
				z-index: 1;
				cursor: pointer;
				transition: all 0.15s ease-in-out;
			}
			&:hover::after {
				transform: translate(-50%, -50%) scale(1.25);
			}
		}
		input {
			display: none;
			&:checked {
				+ label::before {
					font-weight: 545;
					opacity: 1;
				}
				+ label::after {
					border-width: 4px;
					transform: translate(-50%, -50%) scale(0.75);
				}
				~ #pos {
					opacity: 1;
				}
				@for $i from 1 through $number-of-options {
					&:nth-child(#{$i * 2 - 1}) ~ #pos {
						left: #{($i * 10%) - 5%};
					}
				}
			}
		}
		#pos {
			display: block;
			position: absolute;
			top: 50%;
			width: 12px;
			height: 12px;
			background: #000;
			border-radius: 50%;
			transition: all 0.15s ease-in-out;
			transform: translate(-50%, -50%);
			border: 2px solid #fff;
			opacity: 0;
			z-index: 2;
		}
	}
	&:valid {
		.rating-slider {
			input {
				+ label::before {
					transform: translate(-50%, 45px) scale(0.9);
					transition: all 0.15s linear;
				}
				&:checked + label::before {
					transform: translate(-50%, 45px) scale(1.1);
					transition: all 0.15s linear;
				}
			}
		}
	}
	.rating-slider-agreement {
		display: flex;
		flex-direction: row;
		align-content: stretch;
		position: relative;
        margin-bottom: 100px;

		width: 100%;
		height: 50px;
		user-select: none;
		&::before {
			content: " ";
			position: absolute;
			height: 2px;
			width: 100%;
			width: calc(100% * (#{$number-of-options-quiz - 1} / #{$number-of-options-quiz}));
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			background: #000;
		}
		input, label {
			box-sizing: border-box;
			flex: 1;
			user-select: none;
			cursor: pointer;
		}
		label {
			display: inline-block;
			position: relative;
			width: 20%;
			height: 100%;
			user-select: none;
			&::before {
				content: attr(data-rating);
				position: absolute;
				left: 50%;
				padding-top: 10px;
				transform: translate(-50%, 45px);
				font-size: 14px;
				// letter-spacing: 0.4px;
				font-weight: 400;
				// white-space: nowrap;
				opacity: 0.85;
				transition: all 0.15s ease-in-out;
				word-break: break-word;
			}
			&::after {
				content: " ";
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				width: 30px;
				height: 30px;
				border: 2px solid #000;
				background: #fff;
				border-radius: 50%;
				pointer-events: none;
				user-select: none;
				z-index: 1;
				cursor: pointer;
				transition: all 0.15s ease-in-out;
			}
			&:hover::after {
				transform: translate(-50%, -50%) scale(1.25);
			}
		}
		input {
			display: none;
			&:checked {
				+ label::before {
					font-weight: 545;
					opacity: 1;
				}
				+ label::after {
					border-width: 4px;
					transform: translate(-50%, -50%) scale(0.75);
				}
				~ #pos {
					opacity: 1;
				}
				@for $i from 1 through $number-of-options-quiz {
					&:nth-child(#{$i * 2 - 1}) ~ #pos {
						left: #{($i * 25%) - 12.5%};
					}
				}
			}
		}
		#pos {
			display: block;
			position: absolute;
			top: 50%;
			width: 12px;
			height: 12px;
			background: #000;
			border-radius: 50%;
			transition: all 0.15s ease-in-out;
			transform: translate(-50%, -50%);
			border: 2px solid #fff;
			opacity: 0;
			z-index: 20;
		}
	}
	&:valid {
		.rating-slider-agreement {
			input {
				+ label::before {
					transform: translate(-50%, 45px) scale(0.9);
					transition: all 0.15s linear;
				}
				&:checked + label::before {
					transform: translate(-50%, 45px) scale(1.1);
					transition: all 0.15s linear;
				}
			}
		}
	}

}
@keyframes spin {
	from {
		transform: rotate(0deg);
		width: 24px;
		opacity: 1;
		margin-right: 12px;
	}
	to {
		transform: rotate(360deg);
		width: 24px;
		opacity: 1;
		margin-right: 12px;
	}
}


@media (max-width: 800px){
	.rating-form .rating-slider label::after {
		width: 20px;
		height: 20px;
		font-size: 11px;
	}
	.rating-slider-agreement {
		label {
			font-size: 11px !important;
			&::before {
				font-size: 11px !important;
			}
		}
	}
}

@media (max-width: 550px){
	.rating-slider-agreement {
		label {
			font-size: 10px !important;
			&::before {
				font-size: 10px !important;
			}
		}
	}
}

@media (max-width: 450px){
	.rating-slider-agreement {
		label {
			font-size: 9px !important;
			&::before {
				font-size: 9px !important;
			}
		}
	}
}

@media (max-width: 350px){
	.rating-slider-agreement {
		label {
			font-size: 8px !important;
			&::before {
				font-size: 8px !important;
			}
		}
	}
}

.quiz-question-group{
	flex-direction: row !important;
}
.quiz-option-group > div {
	margin: auto !important;
	align-content: space-between;
	padding: 12px !important;
}