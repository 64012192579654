:root {
    --blue: #4a8ac9;
    --red: #f84e62;
    --amber: #e4923a;
    --green: #6ac94a;
    --dark-green: #418929;
    --purple: #8a4ac9;
    --light-blue: #eef7ff;
    --grey: #f2f2f2;
    --yellow: #ffdd13;
    --black: #000000;
    --warn: #fcf8e3;

    --primary-color: #eef7ff;
    --secondary-color: darkblue;

}

.bg-secondary {
    background-color: var(--secondary-color) !important;
}

.slick-track {
    display: flex !important;
}

.slick-slide {
    /* height: auto; */
}

.slick-slide>div {
    /* height: 100%; */
}

.blue {
    color: var(--blue);
}

.blue-back {
    background-color: #cfe9fc;
}

.red {
    color: var(--red);
}

.red-back {
    background-color: var(--red);
}

.amber {
    color: var(--amber);
}

.amber-back {
    background-color: var(--amber);
}

.green {
    color: var(--green);
}

.warn {
    background-color: var(--warn);
}

.dark-green {
    color: var(--dark-green);
}

.green-back {
    background-color: var(--green);
}

.purple {
    color: var(--purple);
}

.purple-back {
    background-color: var(--purple);
}

.light-blue {
    color: var(--light-blue);
}

.light-blue-back {
    background-color: var(--light-blue);
}

.yellow {
    color: var(--yellow);
}

.yellow-back {
    background-color: var(--yellow);
}

.black {
    color: var(--black);
}

.red-dotted-border {
    border: 2px dotted red !important;
}

.black-back {
    background-color: var(--black);
}

body {
    font-family: 'DM Sans', sans-serif;
    font-size: 1rem;
    color: #3a3939;
    overflow-y: scroll;
    /* Show vertical scrollbar */

}

a,
button,
input,
select,
textarea {
    text-decoration: none !important;
    outline: none !important;
}

a,
button {
    transition: all ease-in-out 300ms;
}

a,
a:hover {
    color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1.5;
    font-weight: bold;
}

p {
    line-height: 1.5;
}

a img {
    margin-left: auto;
    margin-right: auto;
}

span img {
    display: inline-block;
}

button img {
    margin: auto;
}

select {
    background: #FFFFFF;
}

input:disabled {
    background: #DDDDDD;
}

button {
    cursor: pointer;
}

.helper {
    --reactour-accent: #5cb7b7;
    line-height: 1.3;
    color: #2d2323;

}

.navigation {
    position: relative;
    background: #eef7ffb8;
}

.main-nav {
    list-style: none;
    padding: 0;
    margin: 0;
}

.wrapper {
    display: flex;
    flex-flow: row wrap;
}

.wrapper .navigation {
    flex: 0 1 auto;
}

.wrapper .main {
    flex: 0 1 calc(100% - 260px);
}

.conversation_preview {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.inner-wrapper {
    margin-left: auto;
    margin-right: auto;
    /* padding-left: 60px;
    padding-right: 60px; */
}

.inner-wrapper.boxed {
    max-width: 1180px;
    width: 100%;
}

.user-top-menu {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
}

.search-bar {
    max-width: 470px;
    width: 100%;
}

.search-bar form {
    display: flex;
    align-items: center;
    background: #eef7ffb8;
    border-radius: 10px;
    width: 100%;
}

.search-bar .search-icon {
    height: 50px;
    border: 0;
    padding: 0;
    background: none;
    line-height: 50px;
    padding-left: 18px;
}

.search-icon img {
    margin-top: auto;
    margin-bottom: auto;
}

.search-bar form input {
    display: block;
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
    background: none;
    border: 0;
    flex-grow: 1;
    max-width: 100%;
    color: #3a3939;
    font-weight: 500;
    font-size: 14px;
}

.search-bar form input::placeholder {
    color: #9fa3ab;
}

.search-filter {
    position: relative;
}

.search-filter .selected {
    position: relative;
    height: 50px;
    line-height: 50px;
    padding-left: 20px;
    padding-right: 30px;
    border: 0;
    background: none;
    color: #3a3939;
    font-weight: bold;
    font-size: 13px;
}

.search-filter .selected:before {
    content: '';
    position: absolute;
    top: 13px;
    left: 0;
    width: 1px;
    height: 24px;
    background: #9fa3ab;
}

.search-filter .selected:after {
    content: '';
    display: inline-block;
    /* background: url('../img/icon-arrow-search.png'); */
    width: 10px;
    height: 6px;
    margin-left: 5px;
    vertical-align: 2px;
    position: absolute;
    top: 23px;
    right: 14px;
}

.search-filter .option {
    position: absolute;
    right: 0;
    top: 100%;
    background: #eef7ffb8;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 2px;
    display: none;
    z-index: 99;
}

.search-filter.open .option {
    display: block;
}

.search-filter .option label {
    display: block;
    width: max-content;
    min-width: 150px;
    position: relative;
    overflow: hidden;
    padding: 10px 16px;
    text-align: center;
    line-height: 1.12;
    cursor: pointer;
    margin: 0;
    transition: all ease-in-out 300ms;
}

.search-filter .option label.active,
.search-filter .option label:hover {
    background: #ccc;
}

.search-filter .option label input {
    position: absolute;
    left: -1000em;
}

.user-bar {
    margin-left: auto;
    margin-right: 0;
    display: flex;
    justify-content: center;
}

.main-nav .icon {
    display: inline-block;
    margin-right: 10px;
    height: 24px;
    line-height: 24px;
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
}

.main-nav .icon:before,
.main-nav .icon:after {
    content: '';
    display: inline-block;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    vertical-align: middle;
    position: absolute;
    top: 0;
    left: 0;
    transition: all ease-in-out 300ms;
}

.main-nav li a {
    display: block;
    width: 100%;
    padding: 10px 15px 10px 50px;
    line-height: 1.1;
    font-size: 16px;
    font-weight: 500;
    color: #848991;
    letter-spacing: 0.02em;
    position: relative;
}

.main-nav .icon:after {
    opacity: 0;
}

.main-nav li.current a {
    color: #fc5e60;
}

.main-nav li a:hover .icon:before,
.main-nav li.current a .icon:before {
    opacity: 0;
}

.main-nav li a:hover .icon:after,
.main-nav li.current a .icon:after {
    opacity: 1;
}

.icon.icon-overview:before {
    /* background-image: url('../img/icon-overview.png'); */
    width: 22px;
    height: 22px;
}

.icon.icon-overview:after {
    /* background-image: url("../img/icon-overview-active.png"); */
    width: 22px;
    height: 22px;
}

.icon.icon-courses:before {
    /* background-image: url('../img/icon-courses.png'); */
    width: 24px;
    height: 20px;
}

.icon.icon-courses:after {
    /* background-image: url("../img/icon-courses-active.png"); */
    width: 24px;
    height: 20px;
}

.icon.icon-worksheets:before {
    /* background-image: url('../img/icon-worksheets.png'); */
    width: 24px;
    height: 20px;
}

.icon.icon-worksheets:after {
    /* background-image: url("../img/icon-worksheets-active.png"); */
    width: 24px;
    height: 20px;
}

.icon.icon-homework:before {
    /* background-image: url('../img/icon-homework.png'); */
    width: 17px;
    height: 22px;
}

.icon.icon-homework:after {
    /* background-image: url("../img/icon-homework-active.png"); */
    width: 17px;
    height: 22px;
}

.icon.icon-student:before {
    /* background-image: url('../img/icon-student.png'); */
    width: 22px;
    height: 22px;
}

.icon.icon-student:after {
    /* background-image: url("../img/icon-student-active.png"); */
    width: 22px;
    height: 22px;
}

.icon.icon-bell:before {
    /* background-image: url('../img/icon-bell.png'); */
    width: 24px;
    height: 20px;
}

.icon.icon-bell:after {
    /* background-image: url("../img/icon-bell-active.png"); */
    width: 24px;
    height: 20px;
}

.icon.icon-attachment:before {
    /* background-image: url('../img/icon-attachment.png'); */
    width: 22px;
    height: 22px;
}

.icon.icon-attachment:after {
    /* background-image: url("../img/icon-attachment.png"); */
    width: 22px;
    height: 22px;
}

.welcome-box {
    border-radius: 16px;
    background-color: #fef9ee;
    /* background-image: url("../img/yellow-bg-shape.png"); */
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: top right;
    padding: 30px 30px;
    position: relative;
    overflow: hidden;
    color: #585651;
    margin-top: 20px;
    margin-bottom: 50px;
}

.welcome-box:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    /* background: url("../img/welcome-box-art.png") no-repeat bottom right; */
    width: 202px;
    height: 110px;
}

.welcome-box .name {
    color: #fc5e60;
    font-size: 24px;
    font-weight: bold;
}

.welcome-box .inner {
    position: relative;
    z-index: 9;
}

/* .widget{
    display: flex;
    flex-flow: row wrap;
    justify-items: flex-start;
    border: 1px solid #e4e5e8;
    border-radius: 16px;
    padding: 30px 15px;
    margin-bottom: 50px;
} */
.widget {
    --margin-bottom: 50px;
    display: flex;
    flex-flow: row wrap;
    justify-items: flex-start;
    border: 1px solid #e4e5e8;
    border-radius: 16px;
    padding: 30px 15px;
    margin-bottom: var(--margin-bottom);
    height: calc(100% - var(--margin-bottom));
}

.widget .head {
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 50px;
    width: 100%;
}

.widget .head>div {
    flex: 0 1 auto;
}

.widget .head .title {
    max-width: 50%;
    font-size: 24px;
    color: #2c2b29;
    font-weight: bold;
    letter-spacing: -0.01em;
}

.widget .action {
    margin-left: auto;
    margin-right: 0;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}

.view-period {
    margin-left: 16px;
    margin-right: 16px;
    font-weight: 500;
    color: #9da6b6;
    font-size: 15px;
}

.view-period.active {
    font-weight: bold;
    color: #3a3939;
}

.action-btn-md {
    width: 40px !important;
    height: 40px !important;
    line-height: 40px !important;
}

.action-btn {
    border-radius: 6px;
    background-color: #eef7ffb8;
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    justify-items: right;
    align-items: center;
    margin-left: 14px;
    padding: 10px 0px;
}

.action-btn:first-child {
    margin-left: 0;
}

.widget .lead {
    font-size: 14px;
    color: #8d95a3;
    font-weight: 500;
    margin-bottom: 25px;
}

.widget .content {
    width: 100%;
}

.widget .bottom {
    align-self: flex-end;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 15px;
}

.widget .bottom a {
    border-radius: 6px;
    background-color: #eef7ffb8;
    height: 32px;
    line-height: 32px;
    width: 45%;
    border: 0;
    display: block;
    text-align: center;
    padding: 10px 15px;
}


.inner-content-box {
    margin-bottom: 18px;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: left center;
    position: relative;
    overflow: hidden;
    border-radius: 16px;
    padding: 20px 20px;
    min-height: 102px;
}

.inner-content-box.icb-large {
    min-height: 150px;
}

.inner-content-box.icb-med {
    min-height: 100px;
}

.inner-content-box.slim {
    min-height: 20px;
}

.inner-content-box>div {
    position: relative;
    z-index: 9;
    flex: 0 1 auto;
}

.course_payment_box {
    height: 100%;
}

.inner-content-box .icb-action {
    margin-left: auto;
    margin-right: 0;
}

.inner-content-box.blue {
    background-color: #cfe9fc;
}

.inner-content-box.blue.clicky:hover {
    background-color: #9fd3f9;
}

.inner-content-box.yellow {
    background-color: #ffffb3;
}

.inner-content-box.yellow.clicky:hover {
    background-color: #ffff80;
}

.inner-content-box.green {
    background-color: #b3ffb3;
    color: #54b135;
}

.inner-content-box.green.clicky:hover {
    background-color: #80ff80;
}

.inner-content-box.dark-green {
    background-color: #b3ffb3;
}

.inner-content-box.dark-green.clicky:hover {
    background-color: #80ff80;
}

.inner-content-box.purple {
    background-color: #d5ccff;
}

.inner-content-box.purple.clicky:hover {
    background-color: #ac99ff;
}

.inner-content-box.grey {
    background-color: #d9d9d9;
}

.inner-content-box.grey.clicky:hover {
    background-color: #bfbfbf;
}

.inner-content-box.red {
    background-color: #ffb3b3;
}

.inner-content-box.red.clicky:hover {
    background-color: #ff8080;
}

.question-btn {
    cursor: auto !important;
    display: block;
    text-align: center;
    border-radius: 5px;
    background-color: #ffffffcc;
    margin: 20px 50px;
    border: none;
    box-shadow: 0px 0px 8px -6px rgba(0, 0, 0, .5);
    color: var(--secondary-color);
    padding: 1.5rem 1rem;
}

.main-btn {
    display: block;
    text-align: center;
    border-radius: 5px;
    background-color: #ffffffcc;
    margin: 20px 50px;
    border: none;
    box-shadow: 0px 0px 8px -6px rgba(0, 0, 0, .5);
    color: var(--secondary-color);
    /* font-size: 1.20rem; */
    /* letter-spacing: 1px; */
    outline: none;
    cursor: pointer;
    padding: 1.5rem 1rem;
}

.second-btn {
    display: block;
    text-align: center;
    border-radius: 5px;
    background-color: #ffffffcc;
    margin: 20px 50px;
    border: none;
    box-shadow: 0px 0px 8px -6px rgba(0, 0, 0, .5);
    color: var(--secondary-color);
    /* font-size: 1.20rem; */
    /* letter-spacing: 1px; */
    outline: none;
    cursor: pointer;
    /* padding: 1.5rem 1rem; */
}

.nav-font {
    color: black !important;
}

.dropdown-menu {
    opacity: 1 !important;
}

.aimbot {
    font-weight: 600;
    color: #0e7d74;
}

.aimbot-black {
    font-weight: 600;
    color: #050505;
}

.aimbot-blue {
    font-weight: 600;
    color: darkblue;
}

.main-btn:focus {
    box-shadow: 0 0 0 0.2rem rgb(38 143 255 / 50%);
}

.main-btn:hover {}

.main-btn.focus {
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

.main-btn:hover {
    text-decoration: none;
}

.rating-headers-sm {
    font-size: 0.8rem;
}

@media (max-width: 767px) {
    .rating-headers {
        font-size: 0.8rem;
    }

    .rating-headers-sm {
        font-size: 0.7rem;
    }

    .main-btn {
        margin: 20px 0px;
    }

    .question-btn {
        margin: 20px 0px;
    }
}

.breadcrumb {
    padding: 0 !important;
}

.breadcrumb-item {
    margin-left: 3px !important;
    font-size: 1rem;
    font-weight: 700;
}

@media (max-width: 550px) {
    .breadcrumb-item {
        font-size: 0.8rem;
    }
}

@media (max-width: 440px) {
    .breadcrumb-item {
        font-size: 0.8rem;
    }
}

@media (max-width: 330px) {
    .breadcrumb-item {
        font-size: 0.6rem;
    }
}

@media (max-width: 450px) {
    .rating-headers {
        font-size: 0.7rem;
    }

    .rating-headers {
        font-size: 0.6rem;
    }

    .main-btn {
        padding: 0.7rem 0.5rem;
        margin: 10px 0px;
    }

    .question-btn {
        padding: 0.7rem 0.5rem;
        margin: 10px 0px;
    }

    .container {
        padding: 0 !important;
        padding-bottom: 10px !important;
    }

    #body {
        padding: 0 !important;

    }

    .mobile-padless {
        padding: 0 !important;
    }

    .mobile-padlow {
        padding: 5px !important;
    }
}

@media (max-width: 992px) and (min-width:451px) {
    .mobile-padless {
        padding: 0 !important;
    }

    .mobile-padlow {
        padding: 10px !important;
    }
}

.inner-content-box .icb-content {
    display: flex;
    align-items: center;
    flex: 0 1 100%;
}

.icb-content .time {
    margin-right: 15px;
    font-size: 14px;
    font-weight: bold;
}

.icb-content .time .from {
    position: relative;
}

.icb-content .time .to {
    position: relative;
}

.icb-content .time .from:after {
    content: '';
    display: block;
    height: 8px;
    width: 2px;
    margin: 5px auto;
}

.icb-content .deleteBtn {
    width: 10%;
}

.inner-content-box.blue .icb-content .time .from {
    color: #225bb2;
}

.inner-content-box.blue .icb-content .time .to {
    color: #225bb2;
}

.inner-content-box.blue .time .from:after {
    background: #a8c3e5;
}

.inner-content-box.yellow .icb-content .time .from {
    color: #9c591e;
}

.inner-content-box.yellow .icb-content .time .to {
    color: #9c591e;
}

.inner-content-box.yellow .time .from:after {
    background: #dcbba9;
}

.inner-content-box.green .icb-content .time .duration {
    color: #0e7d74;
    text-align: center;
    line-height: 1.1;
}

.inner-content-box.grey .icb-content .time .duration {
    text-align: center;
    line-height: 1.1;
}

.inner-content-box.red .icb-content .time .from {
    color: #9a1d1d;
}

.inner-content-box.red .icb-content .time .to {
    color: #9a1d1d;
}

.inner-content-box.red .time .from:after {
    background: #dca8a8;
}

.inner-content-box.red .icb-content .time .duration {
    color: #7c290e;
    text-align: center;
    line-height: 1.1;
}

.icb-content .lesson {
    flex: 1;
}

.icb-content .cat-inline.student p {
    padding-left: 0px;
    margin-bottom: 0px;
}

.icb-content .day {
    font-size: 1.1rem;
    margin-right: 20px;
    width: 80px;
}

@media(min-width: 1080px) and (max-width: 1235px) {
    .icb-content .day {
        margin-right: 10px;
    }
}

.icb-content .cat {
    display: block;
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 2px;
    letter-spacing: 0.05em;
}

.icb-content .title {
    display: block;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.001em;
}

.icb-content .name {
    font-size: 16px;
    font-weight: 500;
    color: #4a4c4e;
    letter-spacing: 0.001em;
}

.icb-content .student .name {
    font-size: 24px;
    font-weight: 500;
    color: #4a4c4e;
    letter-spacing: 0.001em;
}

.icb-content .cat-inline>* {
    display: inline-block;
    padding: 4px 7px;
    margin-right: 5px;
    line-height: 1.1;
    font-size: 11px;
    font-weight: 500;
    border-radius: 4px;
}

.icb-content .cat-inline-large>* {
    padding: 8px 12px;
    font-size: 20px;
}


.content .inner-content .inner-content-box:last-of-type {
    margin-bottom: 0px;
}

.progress-compound {
    background-color: #eef7ffb8;
    padding: 20px 15px;
    margin-top: 50px;
    border-radius: 16px;
}

.progress-compound .item {
    position: relative;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 20px;
    display: flex;
    align-items: center;
}

.progress-compound .item>div {
    flex: 0 1 auto;
}

.progress-compound .item .stat {
    max-width: 80%;
    line-height: 1.2;
    color: #9da6b6;
    font-weight: 500;
    font-size: 14px;
}

.progress-compound .item .stat-num {
    margin-left: auto;
    margin-right: 0;
    font-size: 24px;
    font-weight: bold;
}

.progress-compound .item.line-top {
    padding-top: 20px !important;
}

.progress-compound .item.line-under {
    padding-top: 0;
    padding-bottom: 20px;
    border-bottom: 1px solid #e4e8f2;
}

.line-under-subjects {
    padding-bottom: 1px;
    border-bottom: 1px solid #66b8ff;
}

.line-left-subjects {
    border-left: 1px solid #66b8ff;
}

.progress-compound .row {
    margin-left: -35px;
    margin-right: -35px;
}

.progress-compound .row>div {
    padding-left: 35px;
    padding-right: 35px;
}

.multiple-choice .item {
    border-radius: 8px;
    border: 1px solid #e8ecf0;
    background-color: #fff;
    padding: 30px 40px;
    margin-bottom: 30px;
    font-size: 16px;
    color: #2b2a2a;
    display: flex;
    align-items: center;
    flex-flow: row wrap;
}

.multiple-choice .item .letter {
    font-size: 24px;
    color: #959ca0;
    font-weight: bold;
    margin-right: 30px;
}

.multiple-choice .item.selected {
    border-color: #4bb6c9;
    background-color: #f5fbfd;
    color: #4bb6c9;
}

.multiple-choice .item.selected .letter {
    color: #4bb6c9;
}

.multiple-choice .item.correct {
    border-color: #4ac94a;
    background-color: #f7fdf7;
    color: #6ac94a;
}

.multiple-choice .item.correct .letter {
    color: #6ac94a;
}

.multiple-choice .item.incorrect {
    border-color: #c94a4a;
    background-color: #fdf6f6;
    color: #c94a4a;
}

.multiple-choice .item.incorrect .letter {
    color: #c94a4a;
}

.gate-bg {
    position: relative;
    background: #fff;
    background-size: 100% auto;
}

.gate-art-bg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: 1;
}

.gate-bg:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    /* background: url('../img/gate-art-left.png') no-repeat bottom left; */
    width: 332px;
    height: 259px;
    background-size: auto;
    z-index: 2;
}

.gate-bg:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    /* background: url("../img/gate-art-right.png") no-repeat bottom right; */
    width: 213px;
    height: 284px;
    background-size: auto;
    z-index: 2;
}

.full-frame-wrapper {
    min-height: 100vh;
    padding: 15px 15px 290px;
}

.full-frame-wrapper .content {
    position: relative;
    z-index: 99;
    width: 100%;
    padding-top: 120px;
}

.login-wrapper {
    max-width: 400px;
    width: 100%;
    flex: 1;
    margin-left: auto;
    margin-right: auto;
}

.signup-wrapper {
    max-width: 510px;
    width: 100%;
    flex: 1;
    margin-left: auto;
    margin-right: auto;
}

.form-heading {
    margin-bottom: 60px;
}

.form-heading h1 {
    font-size: 40px;
    color: #2b2a2a;
}

.form-heading p {
    font-size: 20px;
}

.ff-head {
    align-self: flex-start;
    width: 100%;
    flex: 0 1 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}

.ff-head .right {
    margin-left: auto;
    margin-right: 0;
}

.ff-head .right a {
    color: #fc5e60;
    font-weight: 500;
}

.input-wrapper {
    position: relative;
    margin-bottom: 25px;
}

.input-wrapper.mb-extra {
    margin-bottom: 60px;
}

.input-label {
    display: block;
    margin: 0 0 10px 0;
    font-size: 16px;
    font-weight: 500;
    color: #606366;
    line-height: 1.1;
}

.input-label span {
    color: #8c94a5;
}

.input-wrapper .input {
    display: block;
    width: 100%;
    height: 52px;
    line-height: 52px;
    border-radius: 8px;
    border: 1px solid #d0d0d0;
    color: #2b2a2a;
    font-weight: 500;
    font-size: 1rem;
    padding: 0 20px;
    transition: all ease-in-out 300ms;
}

.input-wrapper .input::placeholder {
    color: #b4b8bd;
}

.input-wrapper textarea.input {
    height: auto;
    min-height: 120px;
    resize: vertical;
    line-height: 1.2;
    padding-top: 15px;
    padding-bottom: 15px;
}

.input-wrapper .input.hover,
.input-wrapper .input:hover {
    border-color: #b6bbc1;
}

.input-wrapper .input.focus,
.input-wrapper .input:focus {
    border-color: #acd0d7;
}

select.styled-select-menu {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.select2-container .select2-selection--single {
    display: block;
    width: 100%;
    height: 52px !important;
    line-height: 52px !important;
    padding: 0 30px 0 20px;
    margin: 0;
    border: 1px solid #e0e0e0;
    outline: none !important;
    background: none;
    border-radius: 8px;
    transition: all ease-in-out 300ms;
    color: #2b2a2a;
    font-weight: 500;
}

.select2-container .select2-selection--single:hover {
    border-color: #c6cbd1;
}

.select2-container .select2-selection--single .select2-selection__rendered {
    padding: 0 !important;
    position: relative;
    top: -1px;
    color: #2b2a2a;
    font-weight: 500;
}

.select2-selection__placeholder {
    display: block;
    color: #b4b8bd;
    width: 100%;
    height: 52px;
    line-height: 52px;
}

.select2-container.error .select2-selection--single,
.select2-container .select2-selection--single.error {
    color: red;
}

.select2-container.error .select2-selection--single .select2-selection__placeholder,
.select2-container .select2-selection--single.error .select2-selection__placeholder {
    color: red;
}

.select2-dropdown {
    border: 0;
    background: transparent;
    border-radius: 6px;
    border-color: transparent !important;
}

.select2-results {
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 10px;
}

.select2-results__option {
    padding: 10px 20px;
    color: #2b2a2a;
    font-weight: 500;
}

.select2-results__option[aria-selected="true"],
.select2-results__option.select2-results__option--highlighted {
    background: #fbf8f8;
    color: #fc5e60;
}

.select2-selection__arrow:before {
    content: '';
    position: absolute;
    top: 22px;
    right: 15px;
    /* background: url('../img/icon-angle-down.png') no-repeat top center; */
    background-size: auto;
    width: 14px;
    height: 9px;
}

.secure-note img {
    display: inline-block;
    margin-right: 10px;
}


.order-complete {
    padding-top: 40px;
    padding-bottom: 120px;
}

.order-complete img {
    margin-bottom: 40px;
}

.order-complete h1 {
    color: #229f66;
    font-size: 28px;
    margin-bottom: 40px;
}

.order-complete p {
    font-size: 18px;
    color: #74777a;
    margin-bottom: 60px;
}

.element-page-head {
    flex: 1;
    flex-basis: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    padding: 25px 15px;
    background-color: #fff;
    box-shadow: 0 1px 100px rgba(0, 0, 0, 0.1);
    margin-bottom: 100px;
}

.element-page-head .spacer {
    height: 55px;
    width: 2px;
    background-color: #e5e8ec;
    margin-left: 30px;
    margin-right: 30px;
}

.element-page-head .title {
    color: #353d4d;
    font-weight: bold;
    font-size: 26px;
}

.element-page {
    min-height: 100vh;
    padding-bottom: 120px;
}

.element-heading {
    font-size: 30px;
    margin-bottom: 60px;
    color: #fc5e60;
}

.checkbox-wrapper {
    margin-bottom: 20px;
}

.check-square {
    margin: auto;
    display: block;
    vertical-align: middle;
    /* background-image: url('../img/checkbox.png'); */
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    width: 20px;
    height: 20px;
    line-height: 20px;
    transition: all ease-in-out 300ms;
    cursor: pointer;
}

.checkbox-wrapper .check-square {
    display: inline-block;
    margin-right: 15px;
}

.radio-round {
    display: block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    line-height: 20px;
    /* background-image: url('../img/radio-button-round.png'); */
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    cursor: pointer;
    transition: all ease-in-out 300ms;
}

.checkbox-wrapper .radio-round {
    display: inline-block;
    margin-right: 15px;
}

.radio-round:hover {
    /* background-image: url('../img/radio-button-hover.png'); */
}

.radio-round.checked {
    /* background-image: url('../img/radio-button-active.png'); */
}

.radio-round.checked:hover {
    /* background-image: url('../img/radio-button-active-hover.png'); */
}


.toggle-button {
    display: block;
    vertical-align: middle;
    width: 38px;
    height: 20px;
    line-height: 20px;
    /* background-image: url('../img/toggle-button.png'); */
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    cursor: pointer;
    transition: all ease-in-out 300ms;
}

.checkbox-wrapper .toggle-button {
    display: inline-block;
    margin-right: 15px;
}

.toggle-button:hover {
    /* background-image: url('../img/toggle-button-hover.png'); */
}

.toggle-button.checked {
    /* background-image: url('../img/toggle-button-active.png'); */
}

.toggle-button.checked:hover {
    /* background-image: url('../img/toggle-button-active-hover.png'); */
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-15 {
    margin-bottom: 15px;
}


.small-button {
    display: inline-block;
    vertical-align: top;
    border-radius: 6px;
    background-color: #eef7ffb8;
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    justify-items: right;
    align-items: center;
    border: 0;
}

.small-button img {
    display: inline-block;
    vertical-align: middle;
    margin: 0 auto;
}

.small-button.no-bg {
    background: none;
}

.inline-buttons .small-button {
    margin-right: 5px;
}

.tooltip-button {
    display: inline-block;
    border: 0;
    /* background: transparent url('../img/icon-tooltip.png') no-repeat center; */
    background-size: auto;
    width: 20px;
    height: 20px;
    font-size: 0;
    line-height: 0;
}

.tooltip-button:hover {
    /* background-image: url('../img/icon-tooltip-hover.png'); */
}

.selection-button {
    display: inline-block;
    padding: 12px 15px;
    font-size: 14px;
    line-height: 1.2;
    font-weight: bold;
    color: #2b2a2a !important;
    background: #eef7ffb8;
    border-radius: 8px;
    border: 0;
    min-width: 124px;
}

.selection-button.active {
    background-color: #ffeae7;
    color: #fc5e60 !important;
}

.notification-box {
    position: relative;
    border-radius: 6px;
    font-size: 15px;
    line-height: 1.2;
    padding: 16px 45px;
    margin-bottom: 14px;
    max-width: 80em;
    word-wrap: break-word;
    text-align: center;
}

.notification-box:before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 14px;
    background-size: auto;
    width: 20px;
    height: 20px;
}

.notification-box .close-notification {
    border: 0;
    background-color: transparent;
    padding: 0;
    width: 10px;
    height: 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 17px;
}

.notification-box.large {
    font-size: 20px;
    line-height: 1.5;
    margin: 0 5%;
    padding: 25px 25px 25px 50px;
}

.notification-box.success {
    background-color: #e0f7ec;
    color: #4dc294;
}

.notification-box.success:before {
    /* background: url("../img/info-icon-green.png"); */
}

.notification-box.success .close-notification {
    /* background-image: url("../img/icon-close-green.png"); */
}

.notification-box.information {
    background-color: #e3efff;
    color: #448fdd;
}

.notification-box.information:before {
    /* background: url("../img/icon-info-blue.png"); */
}

.notification-box.information .close-notification {
    /* background-image: url("../img/info-close-blue.png"); */
}

.notification-box.attention {
    background-color: #fff2cf;
    color: #e5a606;
}

.notification-box.attention:before {
    /* background: url("../img/icon-info-yellow.png"); */
}

.notification-box.attention .close-notification {
    /* background-image: url("../img/icon-close-yellow.png"); */
}

.notification-box.error {
    background-color: #ffe1e1;
    color: #e64545;
}

.notification-box.error:before {
    /* background: url("../img/icon-error.png"); */
}

.notification-box.error .close-notification {
    /* background-image: url("../img/icon-close-red.png"); */
}


.calendar-box {
    display: block;
    border-radius: 6px;
    max-width: 290px;
    width: 100%;
}

.calendar-box ul {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 14px;
}

.calendar-box ul:after {
    content: '';
    display: block;
    clear: both;
}

.calendar-box .week,
.calendar-box .day {
    width: 100%;
}

.calendar-box .week li,
.calendar-box .day li {
    float: left;
    width: 14.2857%;
    text-align: center;
    position: relative;
}

.calendar-box .day li {
    margin: 3px 0;
}

.calendar-box .day li:nth-child(1),
.calendar-box .day li:nth-child(7n + 1) {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.calendar-box .day li:nth-child(7n) {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.calendar-box .week li span,
.calendar-box .day li span {
    display: inline-block;
    width: 34px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    position: relative;
    z-index: 9;
}

.calendar-box .week li span {
    font-size: 11px;
    color: #a9acb9;
}

.calendar-box .day li.prev span {
    color: #a9acb9;
}

.calendar-box .day li.current span {
    border-radius: 180%;
    background-color: #f3f4f4;
}

.calendar-box .day li.selected {
    background-color: #faefef;
}

.calendar-box .day li.selected.begin,
.calendar-box .day li.selected.end {
    background-color: transparent;
}

.calendar-box .day li.selected.begin span,
.calendar-box .day li.selected.end span {
    border-radius: 180%;
    background-color: #fc5e60;
    color: #fff;
}

.calendar-box .day li.selected.begin:before,
.calendar-box .day li.selected.end:before {
    content: '';
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
    background-color: #faefef;
    z-index: 1;
}

.calendar-box .day li.selected.begin:before {
    right: 0;
}

.calendar-box .day li.selected.end:before {
    left: 0;
}

.calendar-box .action {
    display: flex;
    padding-top: 10px;
}

.calendar-box .action button {
    padding: 3px;
    border: 0;
    background: none;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
}

.calendar-box .action button.reset {
    color: #a9acb9;
}

.calendar-box .action button.save {
    color: #fc5e60;
    margin-left: auto;
    margin-right: 0;
}

.cal-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.cal-head .month-btn {
    border: 0;
    padding: 3px;
    margin: 0;
    background: none;
}

.cal-head .month {
    font-size: 16px;
    font-weight: bold;
    color: #354052;
}

.calendar-wrapper {
    border-radius: 6px;
    background: #fff;
    padding: 35px 15px;
}

.calendar-wrapper .calendar-box {
    margin-left: auto;
    margin-right: auto;
}

.calendar-wrapper.shadow-box {
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.08);
}

.calendar-wrapper.multiple .first-cal .next-month,
.calendar-wrapper.multiple .last-cal .prev-month {
    visibility: hidden;
    opacity: 0;
}

.calendar-wrapper.grey {
    background-color: #eef7ffb8;
}

.inline-search {
    background: #f5f7f9;
    position: relative;
    max-width: 250px;
    width: 100%;
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
    border-radius: 6px;
}

.inline-search input {
    display: block;
    width: 100%;
    height: 40px;
    line-height: 40px;
    padding-left: 40px;
    padding-right: 30px;
    background: transparent;
    border: 0;
}

.inline-search input::placeholder {
    color: #9fa3ab;
}

.inline-search button {
    position: absolute;
    top: 7px;
    left: 15px;
    background: none;
    border: 0;
    padding: 0;
}

.section-action .small-button {
    width: 40px;
    height: 40px;
    line-height: 40px;
}

.pagination-index {
    font-size: 15px;
    color: #8d949c;
}

.pagination-index span {
    color: #434549;
    display: inline-block;
    padding-left: 3px;
    padding-right: 3px;
}

.action-table {
    width: 100%;
}

.action-table tr th,
.action-table tr td {
    vertical-align: top;
}

.action-table .table-head {
    display: block;
    background-color: #f8f9fb;
    padding: 25px 15px;
    margin-bottom: 16px;
    font-size: 15px;
    color: #32405a;
    font-weight: bold;
}

.action-table .table-head-left {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.action-table .table-head-right {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.table-body {
    background-color: #ffffff;
    border-top: 1px solid #f0f0f0;
    padding: 25px 15px;
    line-height: 1;
    font-size: 16px;
    color: #8d949c;
    /*display: flex;*/
    /*flex-flow: row wrap;*/
    /*align-items: center;*/
}

.action-table tr td:first-child .table-body {
    border-left: 1px solid #f0f0f0;
}

.action-table tr td:last-child .table-body {
    border-right: 1px solid #f0f0f0;
}

.action-table tr:last-child td .table-body {
    border-bottom: 1px solid #f0f0f0;
}

.action-table tbody tr:first-child td:first-child .table-body {
    border-top-left-radius: 8px;
}

.action-table tbody tr:first-child td:last-child .table-body {
    border-top-right-radius: 8px;
}

.action-table tbody tr:last-child td:first-child .table-body {
    border-bottom-left-radius: 8px;
}

.action-table tbody tr:last-child td:last-child .table-body {
    border-bottom-right-radius: 8px;
}

.action-table .checkbox-wrapper {
    margin-bottom: 0;
}

.action-table thead tr th:first-child {
    width: 50px;
}

.action-table thead tr th:nth-child(2) {
    width: 50px;
}

.action-table thead tr th:last-child {
    width: 50px;
}

.action-table .top-button {
    background: none;
    border: 0;
    padding: 0;
    margin-left: 8px;
}

.table-action-button {
    padding: 2px;
    border: 0;
    background: none;
}

.action-table .title {
    color: #434549;
    font-weight: 500;
}

.action-row .button {
    min-width: 180px;
    margin-right: 14px;
}

.q-spacer {
    padding-top: 20px;
    padding-bottom: 50px;
}

.q-spacer>div {
    height: 1px;
    background-color: #ebebeb;
}

.q-spacer-slim {
    padding-top: 20px;
    padding-bottom: 20px;
}

.q-spacer-slim>div {
    height: 1px;
    background-color: #ebebeb;
}

.button-option {
    margin-left: -15px;
    margin-right: -15px;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}

.button-option>button {
    height: 44px;
    line-height: 40px;
    border-radius: 8px;
    margin-left: 15px;
    margin-right: 15px;
    min-width: 124px;
    border: 1px solid transparent;
    background-color: #eef7ffb8;
    color: #2b2a2a;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 28px;
}

.button-option>button.selected {
    border-color: #bee4eb;
    color: #48b0c2;
    background-color: #eef9fb;
}

.group-input {
    border: 1px solid #e2e2e2;
    border-radius: 8px;
    max-width: 160px;
    width: 100%;
    display: flex;
}

.group-input button {
    border: 0;
    background: transparent;
    height: 52px;
    line-height: 52px;
    font-weight: 500;
    color: #626365;
    width: 50px;
    text-align: center;
    font-size: 20px;
}

.group-input input,
.group-input p {
    border: 0;
    font-weight: 500;
    color: #626365;
    font-size: 16px;
    height: 52px;
    line-height: 52px;
    width: 60px;
    text-align: center;
}

.group-input.short * {
    height: calc(1.5em + 0.4rem) !important;
}

.question-area {
    padding-bottom: 60px;
}

.question-item {
    margin-bottom: 24px;
    border: 1px solid #e2e2e2;
    border-radius: 8px;
}

.question-item .head {
    padding: 20px;
    display: flex;
    align-items: center;
}

.question-item .head .action {
    margin-left: auto;
    margin-right: 0;
}

.question-item .head button {
    border: 0;
    background-color: transparent;
}

.question-item .content {
    border-top: 1px solid #e2e2e2;
    padding: 50px 40px;
    display: none;
}

.question-item.open .content {
    display: block;
}

.question-item .head .drag {
    margin-right: 15px;
}

.question-item .head .title {
    font-weight: 500;
    color: #8d949c;
}

.question-item .head .title span {
    padding-left: 8px;
    padding-right: 8px;
}

.question-item .head .title em {
    font-style: normal;
    color: #434549;
}

.question-item .head .action button {
    padding: 0;
    height: 38px;
    line-height: 35px;
    border-radius: 8px;
    border: 1px solid #dbdce0;
    font-size: 14px;
    font-weight: 500;
}

.question-item .head .action .edit-toggle {
    width: 68px;
    background-color: #eef7ffb8;
    border-color: transparent;
}

.question-item.open .head .action .edit-toggle {
    border-color: #dbdce0;
    background-color: transparent;
}

.question-item .head .action .delete {
    width: 42px;
    margin-left: 15px;
    margin-top: auto;
    margin-bottom: auto;
}

.question-item .head .action .action-button {
    padding-left: 10px;
    padding-right: 10px;
    margin-top: auto;
    margin-bottom: auto;
}

.input-wrapper.latex-input .input {
    padding-bottom: 40px;
}

.latex-input.latex-input:after {
    content: '';
    position: absolute;
    bottom: 10px;
    left: 15px;
    /* background: url('../img/icon-latex.png') no-repeat center; */
    background-size: auto;
    width: 39px;
    height: 14px;
}

.question-item .exam-box {
    padding-top: 40px;
    padding-bottom: 40px;
}

.vertical-scroll {
    overflow-y: scroll;
}

.mc-text-box .item {
    display: flex;
    margin-bottom: 16px;
    border: 1px solid #e8ecf0;
    padding: 20px;
    border-radius: 8px;
    align-items: flex-start;
    overflow-x: auto;
}

.mc-answer-box .item {
    display: flex;
    margin-bottom: 16px;
    font-size: 12px;
    border: 1px solid #e8ecf0;
    padding: 20px;
    border-radius: 8px;
    align-items: flex-start;
}

.mc-answer-box .item span {
    margin-right: 14px;
    color: #959ca0;
    font-weight: bold;
    font-size: 14px;
    position: relative;
    top: -1px;
}

.question-action button {
    background: none;
    border: 1px solid transparent;
    color: #2b2a2a;
    font-weight: 500;
    border-radius: 8px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    min-width: 124px;
}

.question-action .add {
    background: #eef7ffb8;
    margin-right: 14px;
}

.question-action .close-item {
    border-color: #dbdce0;
}

.input-wrapper .input-desc {
    color: rgba(96, 99, 102, .7);
    margin-bottom: 20px;
}

.mc-question-box .item {
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    border-radius: 8px;
    border: 1px solid #e2e2e2;
    padding: 20px;
}

.mc-question-box .item button {
    border: 0;
    background: none;
}

.mc-question-box .item .drag {
    margin-right: 10px;
}

.check-question {
    width: 34px;
    height: 34px;
    /* background: url('../img/icon-q-normal.png') no-repeat center; */
    background-size: auto;
    flex: 0 0 auto;
}

.mc-question-box .item .check-question {
    margin-right: 14px;
}

.mc-question-box .item .del {
    margin-left: auto;
    margin-right: 0;
}

.mc-question-box .item .title {
    font-size: 15px;
    font-weight: 500;
    color: #2b2a2a;
    border: 0;
    width: 100%;
    max-width: 74%;
}

.mc-question-box .item .title::placeholder {
    color: #b4b8bd;
}

.mc-question-box .add-a-item {
    color: #32b1c8;
    font-weight: 500;
    background: none;
    border: 0;
}

.action-row {
    padding-top: 30px;
}

.bottom-spacer {
    height: 100px;
}




@media(min-width: 1080px) and (max-width: 1235px) {
    .user-avatar .desc {
        display: none;
    }

    .user-avatar .image {
        margin-right: 0;
    }

    .user-avatar {
        margin-right: 20px;
    }

    .search-bar {
        max-width: 50%;
        background-color: #fff;
    }


}


@media(min-width: 1200px) {
    .exam-wrapper {
        margin-left: -20px;
        margin-right: -20px;
    }

    .exam-wrapper>div {
        padding-left: 20px;
        padding-right: 20px;
    }

    .row.wide {
        margin-left: -30px;
        margin-right: -30px;
    }

    .row.wide>div {
        padding-left: 30px;
        padding-right: 30px;
    }

    .navigation {
        min-height: 100vh;
        width: 260px;
    }

    .user-bar {
        order: 2;
    }

    .user-top-menu {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .toggle-nav {
        display: none;
    }

    .menu-bar {
        display: block;
        padding: 50px 15px 15px;
        text-align: center;
    }

    .main-nav {
        padding-top: 35px;
        padding-bottom: 35px;
        padding-left: 30px;
        padding-right: 30px;
        overflow: hidden;
    }

    .main-nav li {
        position: relative;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .main-nav li.current:before {
        content: '';
        position: absolute;
        width: 47px;
        height: 44px;
        /* background: url('../img/bottom-art.png'); */
        bottom: 67%;
        left: 93%;
        transform: rotate(90deg);
    }

    .main-nav li.current:after {
        content: '';
        position: absolute;
        width: 47px;
        height: 44px;
        /* background: url('../img/bottom-art.png'); */
        top: 85%;
        left: 99%;
    }

    .main-nav li a {
        position: relative;
        padding-top: 16px;
        padding-bottom: 16px;
    }

    .main-nav li a:hover {
        color: #fc5e60;
    }

    .main-nav li.current a {
        background-color: #fff;
        border-radius: 25px;
        z-index: 9;
    }

    .main-nav li.current a:after {
        content: '';
        position: absolute;
        top: 0;
        left: 90%;
        background-color: #fff;
        height: 100%;
        width: 100%;
        z-index: 0;
    }

    .widget {
        padding: 50px 40px;
    }

    .progress-compound {
        padding: 30px 40px;
    }

    .course-title-box {
        padding: 37px 35px;
    }

    .exam-box {
        padding: 50px 40px;
    }

    .checkout-order {
        margin-left: -45px;
        margin-right: -45px;
    }

    .checkout-order>div {
        padding-left: 45px;
        padding-right: 45px;
    }

    .checkout-order .order-content-box {
        flex-basis: calc(100% - 480px);
    }

    .checkout-order .summary-box {
        flex-basis: 480px;
    }
}

@media(max-width: 1079px) {
    .menu-bar {
        padding: 10px 15px;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
    }

    .notifications {
        width: 40vw;
        word-wrap: normal;
    }

    .toggle-nav {
        margin-left: auto;
        margin-right: 0;
        border: 0;
        background: transparent;
        position: relative;
        width: 30px;
        height: 20px;
        padding: 0;
    }

    .toggle-nav span {
        display: block;
        height: 3px;
        width: 70%;
        margin-left: auto;
        margin-right: 0;
        background-color: #343233;
    }

    .toggle-nav:before,
    .toggle-nav:after {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: #343233;
    }

    .toggle-nav:before {
        top: 0;
    }

    .toggle-nav:after {
        bottom: 0;
        left: auto;
        right: 0;
        width: 45%;
    }

    .wrapper .menu-bar {
        border-bottom: 1px solid transparent;
    }

    .main-nav-open .wrapper .menu-bar {
        border-color: rgba(204, 204, 204, 0.5);
    }

    .main-nav {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        z-index: 400;
        background-color: #eef7ffb8;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    body.main-nav-open .main-nav {
        display: block;
    }

    .main-nav li {
        display: block;
        width: 100%;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .main-nav li a {
        padding-top: 16px;
        padding-bottom: 16px;
    }

    .main-nav li.current a {
        background-color: #fff;
    }

    .wrapper .main {
        flex-basis: 100%;
        width: 100%;
    }

    .inner-wrapper {
        padding-left: 15px;
        padding-right: 15px;
    }

    .wrapper .navigation {
        flex-basis: 100%;
        width: 100%;
    }

    .user-bar {
        flex-basis: 100%;
        width: 100%;
    }

    .search-bar {
        margin-left: auto;
        margin-right: auto;
    }

    .user-top-menu {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .user-bar {
        margin-bottom: 30px;
    }

    .user-avatar .desc {
        display: none;
    }

    .user-avatar .image {
        margin-right: 0;
    }

    .user-avatar {
        margin-right: 20px;
    }

    .col-lg-6 {
        max-width: 100%;
        flex-basis: 100%;
    }

    .exam-wrapper>div {
        flex-basis: 100% !important;
    }
}

@media(min-width: 992px) {
    .progress-compound .item:after {
        content: '';
        position: absolute;
        top: 0;
        right: -35px;
        background-color: #e4e8f2;
        width: 1px;
        height: 100%;
    }

    .progress-compound .col-lg-3:nth-child(4n) .item:after {
        display: none;
    }

    .progress-compound.no-after .item:after {
        display: none;
    }

    .lg-text-right {
        text-align: right;
    }
}

@media(max-width: 991px) {
    .gate-bg:after {
        display: none;
    }

    .progress-compound .item {
        padding-top: 20px !important;
        padding-bottom: 20px !important;
        border-bottom: 1px solid #e4e8f2;
    }

    .progress-compound .col-lg-4:last-child .item {
        border-bottom: 0;
    }

    .view-period {
        margin-left: 5px;
        margin-right: 5px;
    }

    .sm-mb-15 {
        margin-bottom: 15px;
    }

    .sm-mb-30 {
        margin-bottom: 30px;
    }

    .element-page-head .spacer {
        margin-left: 20px;
        margin-right: 20px;
    }
}

@media(min-width: 768px) {
    .md-text-right {
        text-align: right;
    }
}

@media(max-width: 767px) {
    .ff-head {
        justify-content: center;
    }

    .ff-head .right {
        padding-top: 15px;
        flex: 0 1 100%;
        text-align: center;
    }

    .welcome-box {
        padding-bottom: 120px;
    }

    .inner-content-box .icb-content {
        flex-basis: 86%;
    }

    .icb-content .time {
        margin-right: 15px;
    }

    .course-title-box>div {
        flex: 0 1 100%;
    }

    .ctb-title,
    .htb-title {
        margin-bottom: 15px;
    }

    .question-action button {
        margin-bottom: 15px;
    }

    .action-row .button {
        margin-bottom: 15px;
    }

    .question-item .head .action {
        width: 67%;
    }
}

.sidenav a,
.dropdown-button {
    padding: 6px 8px 6px 16px;
    text-decoration: none;
    font-size: 20px;
    color: #FFFFFF;
    background-color: #5a8cdb;
    display: block;
    border: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
    outline: none;
}

.sidenav a,
.dropdown-button-selected {
    background-color: #FF00FF;
}

.sidenav a,
.dropdown-button-wrong {
    background-color: #FF0000;
}

.dropdown-container {
    display: none;
    padding-left: 8px;
}

a .block {
    display: block;
}


.sidenav {
    height: 100%;
    width: 200px;
    z-index: 1;
    top: 0;
    left: 0;
    overflow-x: hidden;
    padding-top: 20px;
}

.hasError,
.incorrect,
.incorrect-answer {
    border: 1px solid #c94a4a !important;
    border-radius: 8px !important;
    color: #c94a4a !important;
    background-color: #fdf6f6 !important;
}

.correct,
.correct-answer {
    border: 1px solid #4ac94a !important;
    border-radius: 8px !important;
    color: #6ac94a !important;
    background-color: #f7fdf7 !important;
}

.q-input:focus {
    border: 1px solid #00BB00;
}

img .question-image[src=""] {
    display: none;
}

/* because textarea resizing breaks everything and is hideous */
textarea {
    resize: none !important;
}

/* worksheet title */
#body .border-b {
    border: none;
}

/* table modification  */
.table-bordered thead th,
.table-bordered thead td {
    border: none;
}

table {
    border-collapse: separate;
    border-spacing: 0;
}

/* .table tbody tr:first-child td{
    border-top: 1px solid #dee2e6;
}

.table tbody tr td:first-child{
    border-left: 1px solid #dee2e6;
}

.table tbody tr td:last-child{
    border-right: 1px solid #dee2e6;
}

.table tbody tr td {
    margin: 0;
    border-bottom: 1px solid #dee2e6;
} */
/*
tr:first-child td:first-child {
    border-radius: 8px 0 0 0;
  }
tr:first-child  td:nth-last-child(1) {
    border-radius: 0 8px 0 0;
  }

tr:nth-last-child(1) td:nth-child(1) {
    border-radius: 0 0 0 8px;
  }
tr:nth-last-child(1) td:nth-last-child(1) {
    border-radius: 0 0 8px 0;
  }

  tr th {
    border-top: 7px solid #000;
  } */

.table-head {
    background-color: #f8f9fb;
    padding: 25px 0px;
    margin-bottom: 16px;
    font-size: 15px;
    color: #32405a;
    font-weight: bold;
    height: 70px;
    display: flex;
}

th:nth-child(1)>div.table-head {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

th:last-child>div.table-head {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.table-bordered {
    border: none;
}


.table th {
    padding: 0px 0px 16px;
    border: none;
}

/* table body changes */
.table td {
    margin: 0 16px;
    font-size: 15px;
    padding: 1.4rem .5rem;
    border: none;

}

.icon-delete {
    /* background-image: url('../img/icon-delete.png'); */
    background-repeat: no-repeat, repeat;
    height: 20px;
    padding: 0 6px;
    cursor: pointer;
}

.icon-close {
    /* background-image: url('../img/info-close-blue.png'); */
    background-repeat: no-repeat, repeat;
    height: 10px;
    padding: 0px 5px;
    cursor: pointer;
    border: none;
}

/* .table tbody tr{
    border: 2px solid #f0f0f0;
} */

.caret {
    height: 20px;
    padding: 0 6px;
    cursor: pointer;
    margin: 6px;
    background-repeat: no-repeat, repeat;
}

.caret.caretUp {
    /* background-image: url('../img/icon-caret-up.png'); */
}

.caretDown {
    /* background-image: url('../img/icon-caret-down.png'); */
}

.angle-down {
    /* background-image: url('../img/icon-angle-down.png'); */
    background-repeat: no-repeat;
    vertical-align: middle;
    background-position: center;
    padding: 0 6px;
    cursor: pointer;
    width: 100%;
}

.table-head div {
    display: flex;
}

.no-bg {
    background: none;
}

.delete-btn,
.btn-delete {
    /* background: url(../img/icon-delete.png); */
    background-repeat: no-repeat;
    vertical-align: middle;
    background-position: center;
    padding: 0 6px;
    cursor: pointer;
    width: 100%;
    min-width: 2.6em;
}

.deleteBtn .delete-btn {
    border-color: #999999;
}

.filter-btn {
    /* background: url(../img/icon-filters.png); */
    height: 32px;
    background-repeat: no-repeat;
    vertical-align: middle;
    background-position: center;
    padding: 0 6px;
    margin: 4px 0px;
    cursor: pointer;
}


.search-btn {
    /* background: url(../img/icon-search-small.png); */
    height: 32px;
    background-repeat: no-repeat;
    vertical-align: middle;
    padding: 0 10px;
    margin: 5px 0px;
    cursor: pointer;
}

.section-action {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.inline-search form {
    display: flex;
    align-content: center;
    background: #F5F7F9;
    margin-left: 7px;
    border-radius: 5px;
}

.inline-search form button {
    height: 32px;
}

.inline-search form input {
    background: none;
    border: 0;
    font-weight: 500;
    font-size: 14px;
}

.delete img {
    margin-left: auto;
    margin-right: auto;
}

.delete-slim {
    height: calc(1.5em + .75rem + 2px) !important;
    width: 100%;
    margin-bottom: 0;
    margin-right: 0;
    text-align: center
}

.delete-slim img {
    margin-left: auto;
    margin-right: auto;
}

.wrap-flex {
    display: flex;
    flex-direction: column;
}

.stick-bottom {
    position: sticky;
    bottom: 0.1em;
}

.stick-top {
    position: sticky;
    top: 0.1em;
}

.clickable {
    cursor: pointer;
}

/* .has-error{
  border: 1px solid;
  border-radius: 8px;
  border-color: #FF0000 !important;
} */

.latex-sticky {
    position: sticky;
    top: 3em;
}

.modal {
    display: block;
    z-index: 5000;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
    position: relative;
    background-color: #f4f4f4;
    margin: 25% auto;
    padding: 20px;
    width: 60%;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.17);
    text-align: left;
    border-radius: 10px;
}

@media(max-width: 992px) {
    .modal-content {
        width: 90%;
    }
}

.closeBtn {
    color: #ccc;
    position: absolute;
    top: 0;
    right: 10px;
    font-size: 30px;
    transition: color 500ms;
}

.closeBtn:hover,
.closeBtn:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;

}

.model-confirmation {
    display: flex;
}

.delete-checkbox {
    margin: 0 10px;
}

.deleteButton {
    background-color: #fc5e60;
    color: white;
}

.deleteButton:disabled {
    background-color: gray;
    cursor: default;

}

.hide {
    display: none;
}

div .bordered {
    border: 2px solid #f0f0f0 !important;
}

div .bordered-thin {
    border: 1px solid #e4e5e8 !important;
}

div .bordered-round {
    border: 2px solid #f0f0f0 !important;
    border-radius: 3px;
}

div .table-footer {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.submissionfield {
    height: 90px;
    border: 1px solid #999999;
    border-radius: 7px;
    padding: 5px;
}

.underlined {
    border-bottom: 2px solid #f0f0f0 !important;
}

.clicky {
    cursor: pointer;
}

.center-vertical {
    flex-direction: column;
    justify-content: center;
}

.underline-text {
    text-decoration: underline !important;
}

.color-success {
    background-color: #28a745;
    border-color: #28a745;
}

.date-filters {
    display: flex;
    width: 100%;
    gap: 50px;
}

.date-filters label {
    margin-right: 7px;
}

#score-filter {
    margin: 0 10px 0 0;
}

div.date-filters>div>label>input[type=checkbox] {
    margin-left: 5px;
}

div.action>div:nth-child(1)>input[type=date] {
    margin-right: 7px;
}

.no-border {
    border: none !important;
}

.mr-6,
.mx-6,
.m-6 {
    margin-right: 3.6rem !important;
}

.ml-6,
.mx-6,
.m-6 {
    margin-left: 3.6rem !important;
}

.mt-6,
.my-6,
.m-6 {
    margin-top: 3.6rem !important;
}

.mb-6,
.my-6,
.m-6 {
    margin-bottom: 3.6rem !important;
}

.label {
    padding: 5px;
}

.hint {
    padding: 5px;
    font-style: italic;
}

.slim-button {
    display: inline-block;
    vertical-align: top;
    border-radius: 6px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    align-items: center;
    border: 0;
}

.section-action .slim-button {
    height: 40px;
    line-height: 40px;
}

.katex .mathdefault {
    font-family: KaTeX_Math;
    font-style: italic;
}

/* .katex{
  font: normal 1.21em KaTeX_Math, Times New Roman, serif;
} */

.worksheet_suffix {
    width: 10%;
    margin-left: .5rem !important;
    overflow-x: auto;
}

.form-control-nowidth {
    display: block;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.course-dropdown-item {
    margin-bottom: 24px;
    border: 1px solid #e2e2e2;
    border-radius: 8px;
}

.course-dropdown-item.slim {
    width: 100%;
}

.course-dropdown-item .head {
    padding: 20px;
    display: flex;
    align-items: center;
}

.course-dropdown-item.slim .head {
    padding: 5px;
}

.course-dropdown-item .head .action {
    margin-left: auto;
    margin-right: 0;
}

.course-dropdown-item .head button {
    border: 0;
}

.course-dropdown-item .content {
    border-top: 1px solid #e2e2e2;
    padding: 25px 25px;
    display: none;
}

.course-dropdown-item.slim .content {
    padding: 10px;
}

.course-dropdown-item.open .content {
    display: block;
}

.course-dropdown-item .head .drag {
    margin-right: 15px;
}

.course-dropdown-item .head .title {
    font-weight: 500;
    color: #434549;
}

.course-dropdown-item .head .title span {
    padding-left: 8px;
    padding-right: 8px;
    width: 175px;
    display: inline-block;
}

.course-dropdown-item .head .title span.date-range {
    width: 100%;
    text-align: center;
}

.course-dropdown-item .head .title em {
    font-style: normal;
    font-size: 1.2rem;
    width: 175px;
    display: inline-block;
}

.course-dropdown-item .head .action button {
    padding: 0;
    height: 38px;
    line-height: 35px;
    border-radius: 8px;
    border: 1px solid #dbdce0;
    font-size: 14px;
    font-weight: 500;
}

.course-dropdown-item .head .action .edit-toggle {
    width: 68px;
    background-color: #eef7ffb8;
    border-color: transparent;
}

.course-dropdown-item.open .head .action .edit-toggle {
    border-color: #dbdce0;
    background-color: transparent;
}

.course-dropdown-item .head .action .delete {
    width: 42px;
    margin-left: 15px;
    margin-top: auto;
    margin-bottom: auto;
}

.course-dropdown-item .head .action .action-button {
    padding-left: 10px;
    padding-right: 10px;
    margin-top: auto;
    margin-bottom: auto;
}

.dropdown-head-spacer {
    width: 37px;
}

.red {
    color: #c94a4a;
}

.btn-green {
    background-color: #6ac94a;
}

.nav-icon-color {
    color: #a7adb3;
}

.nav-link.active {
    color: black !important;
}

.lesson-dropdown {
    border-top: 1px solid #e2e2e2;
}

.row.lesson-dropdown-content {
    width: 100%;
    margin-right: 0px;
    margin-left: 0px;
}

.row.lesson-dropdown-content .lesson-dropdown-item {
    display: flex;
}

.row.lesson-dropdown-content .lesson-dropdown-item.center {
    justify-content: center;
}

.row.lesson-dropdown-content .lesson-dropdown-item span,
button {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
}

.row.lesson-dropdown-content .lesson-dropdown-item button {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 0px;
    margin-left: 0px;
    width: 100%;
}

.row.lesson-dropdown-head {
    width: 100%;
    margin-right: 0px;
    margin-left: 0px;
    min-height: 100px;
}

.row.lesson-dropdown-head .lesson-dropdown-item {
    display: flex;
}

.row.lesson-dropdown-head .lesson-dropdown-item.center {
    justify-content: center;
}

.row.lesson-dropdown-head .lesson-dropdown-item span,
button {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
}

.row.lesson-dropdown-head .lesson-dropdown-item button {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 0px;
    margin-left: 0px;
    width: 100%;
    padding-left: inherit;
    padding-right: inherit;
}

.course-dropdown-box {
    display: block;
    overflow: auto;
    background-color: #eef7ffb8;
    padding: 20px;
    border-radius: 16px;
    line-height: 1.2;
    font-size: 15px;
    max-height: 100%;
}

.course-dropdown-box::-webkit-scrollbar {
    width: 0;
    /* Remove scrollbar space */
    background: transparent;
    /* Optional: just make scrollbar invisible */
}

.inline {
    display: inline;
}

.loading_lesson {
    z-index: 1000;
    background-color: #eeeeeeaa;
    width: 60vw;
    text-align: center;
    margin: 0 auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    padding: 20px;
}

.progress-box {
    border: 2px solid #f0f0f0;
    margin-bottom: 18px;
    border-radius: 16px;
    padding: 20px 20px;
}

.progress-box .correct-text {
    color: #4a9d2f;
}

.progress-box .incorrect-text {
    color: #c43b3b;
}

.full-width {
    width: 100%;
}

.min-full-width {
    min-width: 100%;
}

.word-break-all {
    word-break: break-all;
}

/* tbody tr[role=row]:nth-child(even){
  background-color: var(--grey);
}
tbody tr[role=row]:hover{
  background-color: var(--light-blue);
} */

.notebook-table {
    background-color: var(--transparent);
}

.notebook-table tr th,
.notebook-table tr td,
.notebook-table tbody tr {

    background-color: var(--transparent) !important;
    border: 0px !important;
    border-left: 3px dashed var(--green) !important;
    border-bottom: 3px dashed var(--green) !important;
}


@media(max-width: 760px) {
    .course-dropdown-item .head .title em {
        padding-left: 8px;
        padding-right: 8px;
    }

    .hide-on-mobile {
        display: none;
    }
}

/* @media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px){
	table, thead, tbody, th, td, tr {
		display: block;
	}
	thead tr {
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	tr { border: 1px solid #ccc; }
	td {
		border: none;
		border-bottom: 1px solid #eee;
		position: relative;
		padding-left: 50%;
	}
	td:before {
		position: absolute;
		top: 6px;
		left: 6px;
		width: 45%;
		padding-right: 10px;
		white-space: nowrap;
    content: attr(data-title);
	}
  td:empty{
    display: none;
  }
} */

/* Default span styling - hidden on desktop */
.hidden-span {
    background: #eee;
    color: dimgrey;
    display: none;
    font-size: 10px;
    font-weight: bold;
    padding: 5px 5px 5px 5px;
    position: absolute;
    text-transform: uppercase;
    top: 0;
    left: 0;
}

table td[role=cell] {
    vertical-align: middle;
}

/* Simple CSS for flexbox table on mobile */
/* @media(max-width: 800px){
  table thead {
      left: -9999px;
      position: absolute;
      visibility: hidden;
  }
  table tr {
      border-bottom: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: 24px;
  }
  table td {
      border: 0;
      margin: 0 -1px -1px 0;
      position: relative;
      width: 50%;
  }
  table td[role=cell] {
    padding-top: 30px;
  }
  table td:empty, table td[data-title=""] {
    display: none;
  }
  table tbody tr[role=row] {
    border-radius: 4px;
  }
  table tbody tr[role=row] td {
    border: 0 !important;
  }
  /* Show the heading span */
/* .hidden-span {
      display: block;
  }
  .hidden-span:empty{
    display: none;
  }
} */

.mobile-content-center {
    justify-content: center;
}

@media(max-width: 800px) {
    .main {
        margin: 0 !important;
    }
}

.mc-question-box .item.title {
    max-width: 100%;
}

.rbc-time-header-gutter .available,
.rbc-time-column .available,
.rbc-time-slot .available {
    border: 0px !important;
    background: rgba(51, 247, 51, 0.61);
}

.rbc-current-time-indicator {
    background-color: var(--black) !important;
}

.rbc-row-bg .available {
    border: 0px !important;
    background: rgba(51, 247, 51, 0.61);
}

.graph_y_axis_label {
    font-size: 1rem;
    color: black;
    letter-spacing: 1px;
    padding: 5px 200px;
    margin: 0 0 10px 0;
    line-height: 24px;
    position: absolute;
    bottom: 0;
    left: 0;
    margin-left: -30px;
    transform: rotate(270deg);
    transform-origin: 0 0;
}

.graph_x_axis_label {
    font-size: 1rem;
    color: black;
    letter-spacing: 1px;
    text-align: center;
}

aside {
    display: block;
    position: relative;
}

@media(min-width:992px) and (max-width: 1199px) {
    .no-pad-if-small {
        padding: 0 !important;
    }

    .reactour_helper_large {
        max-width: 90% !important;
        transform: translate(5%, 10%) !important;
    }
}

@media(max-width: 992px) {

    .reactour_helper_large {
        max-width: 100% !important;
        transform: translate(0%, 10%) !important;
        padding-left: 5px;
        padding-right: 5px;

    }

    .margin-top-if-small {
        margin-top: 0.5rem !important;
    }

    .no-pad-if-small {
        padding: 0px !important;
    }
}

/* Additions For Template */

/* Slideshow  */
.each-slide>div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 100vh;
    -webkit-filter: blur(5px);
    /* Safari 6.0 - 9.0 */
    filter: blur(5px);
}

.accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1rem 1.25rem;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    background-color: #fff;
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease
}

@media (prefers-reduced-motion:reduce) {
    .accordion-button {
        transition: none
    }
}

.accordion-button:not(.collapsed) {
    color: #0c63e4;
    background-color: #e7f1ff;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .125)
}

.accordion-button:not(.collapsed):after {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
    transform: rotate(-180deg)
}

.accordion-button:after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform .2s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .accordion-button:after {
        transition: none
    }
}

.accordion-button:hover {
    z-index: 2
}

.accordion-button:focus {
    z-index: 3;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25)
}

.accordion-header {
    margin-bottom: 0
}

.accordion-item {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .125)
}

.accordion-item:first-of-type {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem
}

.accordion-item:first-of-type .accordion-button {
    border-top-left-radius: calc(.25rem - 1px);
    border-top-right-radius: calc(.25rem - 1px)
}

.accordion-item:not(:first-of-type) {
    border-top: 0
}

.accordion-item:last-of-type {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: calc(.25rem - 1px);
    border-bottom-left-radius: calc(.25rem - 1px)
}

.accordion-item:last-of-type .accordion-collapse {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.accordion-flush .accordion-collapse {
    border-width: 0
}

.accordion-flush .accordion-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0
}

.accordion-flush .accordion-item:first-child {
    border-top: 0
}

.accordion-flush .accordion-item:last-child {
    border-bottom: 0
}

.accordion-flush .accordion-item .accordion-button {
    border-radius: 0
}

.accordion-button:not(.collapsed) {
    color: #0c63e4;
    background-color: #e7f1ff;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

.each-slide span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
}

.slick-prev {
    left: 1%;
    z-index: 1;
}

.slick-next {
    right: 1%;
}

.slick-next:before,
.slick-prev:before {
    color: var(--secondary-color);
}

@media(min-width: 1235px) {
    .slick-list {
        margin: 50px;
    }
}

@media(min-width: 767px) and (max-width: 1235px) {
    .slick-list {
        margin: 40px;
    }
}

@media(max-width: 767px) {
    .slick-list {
        margin: 20px;
    }
}


/* Login and Signup Form */
.login-container {
    height: 100vh;
    background-color: var(--primary-color);

}

#root {
    background-color: var(--primary-color);
    min-height: 100vh;
}

body {
    background-color: var(--primary-color);
}

.bg-custom {
    background-color: var(--secondary-color) !important;
}

.btn-close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: 0.25em;
    color: #000;
    background: transparent url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3E%3Cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3E%3C/svg%3E") 50%/1em auto no-repeat;
    border: 0;
    border-radius: 0.25rem;
    opacity: .5;
}

.search-grid-items {
    flex: 0 0 200px;
    margin: 10px;
    border: 1px solid #ccc;
    box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3);
}

@media (min-width: 767px) and (max-width: 1235px) {
    .search-grid-items {
        font-size: smaller;
    }
}

.extra-grid-items {
    flex: -5 0 250px;
    margin: 10px;
    border: 1px solid #ccc;
    box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3);
}

.other-question-types {
    flex: -5 0 250px;
    margin: 10px;
    border: 1px solid #ccc;
    box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3);
}

.question-grid-items {
    flex: 0 0 150px;
    margin: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3);
}

.login-card {
    padding: 20px;
    border-radius: 8px;
    height: 600px;
}

.home-card {
    padding: 15px;
    border-radius: 8px;
    height: 600px;
}

.how-does-it-work-card {
    padding: 15px;
    border-radius: 8px;
}

.what_do_i_do_card {
    padding: 15px;
    border-radius: 8px;
}

/* .i-know-card {
    background-color: #ffffff;
    padding: 15px;
    border-radius: 8px;
    height: 650px;
} */

.fixed-footer {
    bottom: 0 !important;
    position: inherit !important;
    width: 100% !important;
}

/* New Navigation Bar */
@media screen and (max-width: 770px) {
    .nav-sidebar {
        display: none !important;
    }
}


@media only screen and (min-width: 770px) {
    .navigation-item {
        border-right: 1px solid rgba(0, 0, 0, 0.1);
    }

    .nav-top-bar .sidebar-navigation-item {
        display: none !important;
    }
}

/* End of new navigation bar */

.admin-pages {
    background-color: rgba(255, 252, 41, 0.25);
}

.thinking-pages {
    background-color: rgba(234, 238, 13, 0.25);
}

.broad {
    background-color: rgba(11, 189, 50, 0.25);
}

.specific {
    background-color: rgba(11, 189, 50, 0.25);
}

.quality {
    background-color: rgba(255, 155, 151, 0.25);
}

.element {
    background-color: rgba(143, 175, 228, 0.25);
}

.i-would-like {
    background-color: rgba(142, 237, 164, 0.25);
}

.question-categories {
    background-color: rgba(163, 132, 176, 0.25);
}

.questions {
    background-color: rgba(163, 132, 176, 0.25);
}

.decision-tree {
    background-color: rgba(13, 221, 58, 0.25);
}

.search {
    background-color: rgba(255, 255, 0, 0.25);
}

.browse {
    background-color: rgba(0, 0, 255, 0.25);
}

.help-pages {
    background-color: rgba(228, 228, 212, 0.25);
}

.journey_one {
    background-color: rgba(167, 199, 231, 0.25);
}

.journey_one_noop {
    background-color: rgba(220, 235, 249);
}

.journey_three_noop {
    background-color: rgba(208, 238, 244);
}

/* https://htmlcolorcodes.com/colors/pastel-blue/ */
.journey_two {
    background-color: rgba(245, 6, 245, 0.25);
}

.journey_three {
    background-color: rgba(152, 222, 222, 0.35);
}

.journey_three_quality {
    background-color: rgba(152, 222, 222, 0.20);
}

.journey_four {
    background-color: rgb(245, 209, 193);
}

.journey_four_aims {
    background-color: rgb(255, 234, 225);
}

.journey_four_elements {
    background-color: rgba(167, 199, 231, 0.25);
}

.notebook {
    background-color: rgba(255, 255, 255, 0);
}

.guides {
    background-color: rgba(229, 220, 249);
}

.supporting {
    background-color: rgba(197, 178, 240);
}

.specialism {
    background-color: rgba(208, 206, 206);
}

.notes {
    background-color: rgba(244, 234, 196);
}

.header-flex {
    display: flex;
    justify-content: space-between;
}

.rounded-border {
    border: 0.5px solid grey;
    border-radius: 10px;
}

.breadcrumb {
    font-size: 10px;
    margin-bottom: 5px;
}

.footer-row {
    display: flex;
    flex-direction: row;
    padding: 10px 0;
}

.footer-text-container {
    flex-direction: column;
    justify-content: center;
    align-self: center;
}

.btn-scale {
    /* min-width: 44px;
    width: 6%; */
    text-align: center;
    color: black;
    font-family: 'Lato', sans-serif;
}

button:active {
    background: #e5e5e5;
    -webkit-box-shadow: inset 0px 0px 5px #c1c1c1;
    -moz-box-shadow: inset 0px 0px 5px #c1c1c1;
    box-shadow: inset 0px 0px 5px #c1c1c1;
    outline: none;
}

/* Chat containers */
.chat-container {}

/* Darker chat container */
.darker {
    border-color: #ccc;
    background-color: #ddd;
}

/* Clear floats */
.chat-container::after {
    content: "";
    clear: both;
    display: table;
}

/* Style images */
.chat-container img {
    float: left;
    max-width: 40px;
    width: 100%;
    margin-right: 20px;
    border-radius: 50%;
}

/* Style the right image */
.chat-container img.right {
    float: right;
    margin-left: 20px;
    margin-right: 0;
}

/* Style images */
.contain_upload img {
    max-width: 200px;
    max-height: 200px;
    width: 100%;
    margin-right: 20px;
    /* border-radius: 50%; */
}

/* Style time text */
.time-right {
    color: rgb(107, 107, 107);
}

.time-right-questions {
    color: var(--secondary-color);
}

/* Style time text */
.time-left {
    float: left;
    color: #999;
}

.blurred-text {
    color: transparent;
    text-shadow: 0 0 8px #0000004a;
}

.blurred-text a {
    color: transparent !important;
    text-shadow: 0 0 8px #0000004a !important;
}

.sample-green {
    color: green;
}

.App-header {
    background-color: #ffffff07;
    min-height: 5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: rgb(7, 7, 7);
    margin-bottom: 5vh;
}

.wrapper-class {
    padding: 1rem;
}

.editor-class {
    background-color: rgb(255, 255, 255);
    padding: 1rem;
    border: 1px solid #ccc;
    height: 80vh;
}

.toolbar-class {
    border: 1px solid #ccc;
}

.password-btn {
    background-color: #007bff !important;
    border: "0px" !important;
    color: "white" !important;
    height: "40px";
    width: "40px";
    margin-left: -2px !important;
    border: 0px !important;
}

.quiz-btn {
    background-color: #F5D1C1 !important;
    color: black !important;
    border: '0px' !important;
    border-radius: '0px' !important;
}

@media (max-width: 800px) {
    .mobile-icons {
        display: none;
    }
}

.consent-banner {
    background-color: #D1ECF1 !important;
}

textarea::-webkit-input-placeholder {
    color: #6C757E;
}

textarea:-moz-placeholder {
    /* Firefox 18- */
    color: #6C757E;
}

textarea::-moz-placeholder {
    /* Firefox 19+ */
    color: #6C757E;
}

textarea:-ms-input-placeholder {
    color: #6C757E;
}

textarea::placeholder {
    color: #6C757E;
}